<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="textChange"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on" @click="init">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-slider
              :label="$vuetify.lang.t('$vuetify.key.K_%E6%B0%B4%E5%B9%B3%E4%BD%8D%E7%A7%BB')"
              v-model="offsetX"
              min="-20"
              max="20"
              @input="change"
            >
              <template slot="append">{{ offsetX }}</template>
            </v-slider>
            <v-slider
              :label="$vuetify.lang.t('$vuetify.key.K_%E5%9E%82%E7%9B%B4%E4%BD%8D%E7%A7%BB')"
              v-model="offsetY"
              min="-20"
              max="20"
              @input="change"
            >
              <template slot="append">{{ offsetY }}</template>
            </v-slider>
            <v-slider
              :label="$vuetify.lang.t('$vuetify.key.K_%E8%B7%9D%E7%A6%BB')"
              v-model="distance"
              min="0"
              max="20"
              @input="change"
            >
              <template slot="append">{{ distance }}</template>
            </v-slider>
            <v-color-picker
              show-swatches
              v-model="color"
              @input="change"
            ></v-color-picker>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      offsetX: 0,
      offsetY: 0,
      distance: 0,
      color: "#000000",
    };
  },
  methods: {
    init() {
      let vals = this.value.split(" ");
      if (vals.length == 4) {
        this.offsetX = parseInt(vals[0]);
        this.offsetY = parseInt(vals[1]);
        this.distance = parseInt(vals[2]);
        this.color = vals[3];
      }
    },
    textChange(value) {
      this.$emit("change", value);
    },
    change() {
      this.$emit(
        "change",
        `${this.offsetX}px ${this.offsetY}px ${this.distance}px ${this.color}`
      );
    },
  },
};
</script>
